// src/App.js
import React from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import AccessAccount from "./components/Auth/AccessAccount";
import EmailLinkHandler from "./components/Auth/EmailLinkHandler";
import Navbar from "./components/common/Navbar";
import PrivateRoute from "./components/common/PrivateRoute";
import ScrollToTop from "./components/common/ScrollToTop";
import Dashboard from "./components/Dashboard/Dashboard";
import Home from "./components/Home/Home";
import SearchResults from "./components/Search/SearchResults";
import { AuthProvider } from "./contexts/AuthContext";
import { UserDataProvider } from "./contexts/UserDataContext";

function App() {
  return (
    <Router>
      <AuthProvider>
        <UserDataProvider>
          <AppRoutes />
        </UserDataProvider>
      </AuthProvider>
    </Router>
  );
}

const AppRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <EmailLinkHandler />
      <Navbar />
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/access-account" element={<AccessAccount />} />
        <Route path="/search" element={<SearchResults />} />

        {/* Private Routes */}
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        {/* terms-of-service */}
      
        {/* about */}

        {/* Default route redirect to home */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
};

export default App;
