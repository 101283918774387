import {
  and,
  collection,
  getDocs,
  or,
  query,
  where,
} from "firebase/firestore";
import { useCallback, useEffect, useState } from 'react';
import { getAllCountiesByState, getCountyOrientation } from '../constants';
import { firestore } from "../firebase/firebase";
import { capitalizeWords, formatCurrency } from '../utils/helpers';

const ITEMS_PER_PAGE = 25;

// Utility to build the base conditions for Firebase query
const buildBaseConditions = (state) => {
  const conditions = [];
  if (state) {
    conditions.push(where("state", "==", state.toLowerCase()));
  }
  return conditions;
};

// Utility to build keyword query
const buildKeywordQuery = (keyword, baseConditions) => {
  const keywordConditions = [
    ...baseConditions,
    or(
      where("incidentSummary", "array-contains", keyword),
      where("injuriesSummary", "array-contains", keyword),
      where("medicalTreatmentsSummary", "array-contains", keyword),
      where("damageSummary", "array-contains", keyword)
    )
  ];
  return and(...keywordConditions);
};

// Helper function to calculate analytics
const calculateAnalytics = (cases) => {
  if (cases.length === 0) return null;

  const values = cases.map((c) => c.totalAwarded);
  const sortedValues = [...values].sort((a, b) => a - b);
  const median = sortedValues.length % 2 === 0
    ? (sortedValues[sortedValues.length / 2 - 1] + sortedValues[sortedValues.length / 2]) / 2
    : sortedValues[Math.floor(sortedValues.length / 2)];

  return {
    lowestCounty: cases.reduce((prev, current) =>
      prev.totalAwarded < current.totalAwarded ? prev : current
    ).county,
    highestCounty: cases.reduce((prev, current) =>
      prev.totalAwarded > current.totalAwarded ? prev : current
    ).county,
    medianValue: formatCurrency(median), // Apply formatCurrency to median
    range: {
      min: formatCurrency(Math.min(...values)), // Apply formatCurrency to min
      max: formatCurrency(Math.max(...values)), // Apply formatCurrency to max
    },
  };
};

// Helper to paginate cases
const paginateCases = (cases, currentPage, itemsPerPage) => {
  const startIndex = (currentPage - 1) * itemsPerPage;
  return cases.slice(startIndex, startIndex + itemsPerPage);
};



const useCasesSearch = (searchParams, updateQueryParams, credits, unlimitedAccess, loading) => {

  // const { decrementCredit } = useAuth();
  const [allCases, setAllCases] = useState([]);
  const [dbCases, setDbCases] = useState([]);
  const [displayedCases, setDisplayedCases] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [isLoading, setIsLoading] = useState(loading || unlimitedAccess === null);
  const [error, setError] = useState(null);
  const [analytics, setAnalytics] = useState(null);
  const [hasValidSearch, setHasValidSearch] = useState(false);

  const currentPage = parseInt(searchParams.get("page")) || 1;
  const state = searchParams.get("state");
  const keywords = searchParams.get("keywords");
  const resolution = searchParams.get("resolution");
  const county = searchParams.get("county");
  const winner = searchParams.get("winner");
  const orientation = searchParams.get("countyOrientation");
  const type = searchParams.get("type");
  const nuclearVerdicts = searchParams.get("nuclearVerdicts");

  const fetchCases = async () => {

    setIsLoading(true);
    setError(null);
    updateQueryParams({ page: 1 });

    try {
      const baseConditions = buildBaseConditions(state);
      const keywordArray = keywords ? keywords.toLowerCase().split(" ").filter(k => k.length > 0) : [];


      let fetchedCases = [];
      if (keywordArray.length === 0) {
        const casesRef = collection(firestore, "cases2");
        const queryConditions = and(...baseConditions);
        const querySnapshot = await getDocs(query(casesRef, queryConditions));
        fetchedCases = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      } else {
        let allCasesMap = new Map();
        for (const keyword of keywordArray) {
          const casesRef = collection(firestore, "cases2");
          const queryConditions = buildKeywordQuery(keyword, baseConditions);
          const querySnapshot = await getDocs(query(casesRef, queryConditions));
          querySnapshot.docs.forEach(doc => {
            allCasesMap.set(doc.id, { id: doc.id, ...doc.data() });
          });
        }
        fetchedCases = Array.from(allCasesMap.values()).sort((a, b) => b.totalAwarded - a.totalAwarded);
      }

      // console.log("setting all cases from fetchCases()");
      setDbCases(fetchedCases);
      if (credits > 0 || unlimitedAccess) {


        setHasValidSearch(true);
      }

      // Apply filters immediately after fetch
      applyFilters(fetchedCases);
    } catch (error) {
      console.error("Error fetching cases:", error);
      setError("An error occurred while fetching the search results. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  // Separate function to apply filters
  const applyFilters = useCallback((cases) => {

    let filteredCases = cases;

    if (county) {
      filteredCases = filteredCases.filter(c => {


        // capitalizeWords(c.county).replace(/\s*(county)\b/i, '').trim() === county.replace(/\s*(county)\b/i, '').trim()
        // return capitalizeWords(c.county).replace(/\s*county\b/i, '').trim().toLowerCase().includes(county.replace(/\s*county\b/i, '').trim().toLowerCase())
        return String(c.county)?.toLowerCase()?.trim()?.replace(/\s*county\b/i, '') === (county.toLowerCase()?.trim()?.replace(/\s*county\b/i, '')) || String(c.county)?.toLowerCase()?.trim()?.replace(/\s*county\b/i, '')?.includes(county.toLowerCase()?.trim()?.replace(/\s*county\b/i, ''))
      }
      );
    }

    if (orientation) {
      console.log("fiuletring by orientation", orientation);

      filteredCases = filteredCases.filter(c => {


        const result = getCountyOrientation(state, capitalizeWords(c.county.replace(/\s*county\b/i, '').trim())).toLowerCase().includes(orientation.toLowerCase()) ||
          getCountyOrientation(state, capitalizeWords(c.county.replace(/\s*county\b/i, '').trim())).toLowerCase().includes(orientation.toLowerCase().replace(/\s*county\b/i, '').trim());
        return result;
      });
      // filteredCases = filteredCases.filter(c => {
      //   // First check if county exists and clean it
      //   const countyValue = c.county?.trim().toLowerCase();
      //   console.log("countyValue", countyValue);

      //   // Return false for invalid county values
      //   if (!countyValue ||
      //     countyValue === 'n/a' ||
      //     countyValue === 'unspecific' ||
      //     countyValue === 'undefined') {
      //     return false;
      //   }

      //   // Clean and standardize the county name
      //   const cleanedCounty = capitalizeWords(
      //     // countyValue.replace(/\s*county\b/i, '').trim()
      //     countyValue.replace(/\s*(county)\b/i, '').trim()
      //   );

      //   // Verify county exists in the state
      //   const validCounties = getAllCountiesByState(state).map(x => x.value);
      //   if (!validCounties.includes(cleanedCounty)) {
      //     return false;
      //   }

      //   // Check orientation
      //   const countyOrientation = getCountyOrientation(state, cleanedCounty)
      //     .toLowerCase();
      //     // console.log(countyValue,"=>countyOrientation", countyOrientation, orientation.toLowerCase());

      //   return countyOrientation.includes(orientation.toLowerCase());
      // });
    }

    if (winner) {
      filteredCases = filteredCases.filter(c => {
        const condition = String(winner).toLowerCase() === "plaintiff" ? "isPlaintiffVerdict" : "isDefenseVerdict";
        return c[condition];
      });
    }

    if (resolution) {
      filteredCases = filteredCases.filter(c =>
        String(c.resolution).toLowerCase() === String(resolution).toLowerCase()
      );
    }

    if (type) {
      filteredCases = filteredCases.filter(c =>
        String(c.caseType).toLowerCase() === String(type).toLowerCase()
      );
    }

    if (nuclearVerdicts === "Exclude") {
      filteredCases = filteredCases.filter(c => c.totalAwarded <= 9999999);
    }

    setAllCases(filteredCases);
    setTotalResults(filteredCases.length);
  }, [county, winner, resolution, orientation, type, nuclearVerdicts, state]);

  // Fetch cases on initial load or when search params change
  useEffect(() => {
    if (unlimitedAccess !== null) {
      console.log("calling database", unlimitedAccess, loading);

      fetchCases();
    }
  }, [state, keywords, unlimitedAccess, loading]);

  // Apply filters when specific filters change
  useEffect(() => {
    if (dbCases.length) {
      applyFilters(dbCases);
    }
  }, [orientation, winner, resolution, county, type, nuclearVerdicts, dbCases, hasValidSearch, applyFilters]);

  // Handle pagination
  useEffect(() => {
    const paginatedCases = paginateCases(allCases, currentPage, ITEMS_PER_PAGE);
    setDisplayedCases(paginatedCases);
    setAnalytics(calculateAnalytics(allCases));
  }, [currentPage, allCases]);

  return {
    cases: displayedCases,
    hasValidSearch,
    totalResults,
    isLoading,
    error,
    analytics,
    ITEMS_PER_PAGE,
  };
};

export default useCasesSearch;

