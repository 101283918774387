import React from "react";
import "./SearchResults.css";
import {
  Types,
  CountyOrientation,
  Resolution,
  Winner,
  getAllCountiesByState,
  nuclearVerdicts,
} from "../../constants/index";
import useQueryParams from "../../hooks/useQueryParams";
import { useNavigate } from "react-router-dom";
import { FaUndo } from 'react-icons/fa';
const FilterBoxex = () => {
  const { searchParams, updateQueryParams } = useQueryParams();
  const navigate = useNavigate();
  const selectedType = searchParams.get("type") || "Any";
  const state = searchParams.get("state");
  const keywords = searchParams.get("keywords");
  const selectedCountyOrientation =
    searchParams.get("countyOrientation") || "Any";
  const selectedResolution = searchParams.get("resolution") || "Any";
  const selectedWinner = searchParams.get("winner") || "Any";
  const selectedCounty = searchParams.get("county") || "Any";
  const selectedNuclearVerdicts = searchParams.get("nuclearVerdicts") || "Any";

  return (
    <div className="filter">
      <div className="box">
        <span>Case Type</span>
        <div className="filterBox">
          <select
            className="filterBox"
            value={selectedType}
            style={{ width: "100%" }}
            onChange={(e) => updateQueryParams({ type: e.target.value })}
          >
            {Types.map((type) => (
              <option key={type.value} value={type.value}>
                {type.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="box">
        <span>County Orientation</span>
        <div className="filterBox">
          <select
            className="filterBox"
            style={{ width: "100%" }}
            value={selectedCountyOrientation}
            onChange={(e) =>
              updateQueryParams({ countyOrientation: e.target.value })
            }
          >
            {CountyOrientation.map((orientation) => (
              <option key={orientation.value} value={orientation.value}>
                {orientation.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="box">
        <span>Resolution</span>
        <div className="filterBox">
          <select
            className="filterBox"
            style={{ width: "100%" }}
            value={selectedResolution}
            onChange={(e) => updateQueryParams({ resolution: e.target.value })}
          >
            {Resolution.map((resolution) => (
              <option key={resolution.value} value={resolution.value}>
                {resolution.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="box">
        <span>Winner</span>
        <div className="filterBox">
          <select
            className="filterBox"
            style={{ width: "100%" }}
            value={selectedWinner}
            onChange={(e) => updateQueryParams({ winner: e.target.value })}
          >
            {Winner.map((winner) => (
              <option key={winner.value} value={winner.value}>
                {winner.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="box">
        <span>County</span>
        <div className="filterBox">
          <select
            className="filterBox"
            value={selectedCounty}
            style={{ width: "100%" }}
            onChange={(e) => updateQueryParams({ county: e.target.value })}
          >
            <option value="Any">Any</option>
            {getAllCountiesByState(state).map((county,i) => (
              <option key={county.value+i} value={county.value}>
                {county.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="box">
        <span>Nuclear Verdicts</span>
        <div className="filterBox">
          <select
            className="filterBox"
            style={{ width: "100%" }}
            value={selectedNuclearVerdicts}
            onChange={(e) =>
              updateQueryParams({ nuclearVerdicts: e.target.value })
            }
          >
            {nuclearVerdicts.map((nuclearVerdict) => (
              <option key={nuclearVerdict.value} value={nuclearVerdict.value}>
                {nuclearVerdict.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
        }}
      >
        {/* Reset Button */}
        <button
          className="btn "
          onClick={() =>
            navigate(`/?state=${state}&keywords=${keywords}`)
          }
        >
          New Search
        </button>{" "}
        <button
          className="btn bg-gray reset-btn" 
          onClick={() =>
            updateQueryParams({
              state,
              keywords,
              page: 1,
              type: "",
              countyOrientation: "",
              resolution: "",
              winner: "",
              county: "",
              nuclearVerdicts: "",
            })
          }
        >
        <FaUndo  color="white" width={20} height={20} />  Reset Filters
        </button>
      </div>
    </div>
  );
};

export default FilterBoxex;
