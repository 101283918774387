import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import "./nav.css";
import { useUserData } from "../../contexts/UserDataContext";
import toast from "react-hot-toast";
function Navbar() {
  const navigate = useNavigate();
  const { currentUser, logout, loading } = useAuth();
  const { credits ,dataLoading} = useUserData();
  const location= useLocation()
  const pathname = location.pathname;
  const handleCredit = () => {
    // if credits are 0 then toast that u need to invite some friends
    // else show the credits
    // write code

    if (Number(credits) === 0 || !credits) {
      toast.error("You need to invite a friend to earn 2 credits");
    }
    if(pathname !== "/dashboard"){
      navigate("/dashboard");

    }
  };
  return (
    <nav>
      <div className="nav-container">
        <Link to="/" className="nav-logo">
          <img
            // remove bg
            style={{
              mixBlendMode: "multiply",
            }}
            src="/logo.webp"
            loading="eager"
            alt="Painworth free verdicts and settlements search"
          />
         
        </Link>

        <div className="nav-links">
          {currentUser ? (
            <div className="dashboard">
              <Link to="/dashboard" className="nav-link">
                Dashboard
              </Link>
              <div className="credits-display" onClick={handleCredit}>
             {!dataLoading ?   <span className="credits-count">{credits || 0}</span>:null}
                <span className="credits-label">Credits</span>
              </div>

              <span className="nav-link" onClick={logout}>
                Logout
              </span>
            </div>
          ) : !loading ? (
            <Link to="/access-account" className="access-link">
              Access Account
            </Link>
          ) : null}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
