export function capitalizeWords(str) {
  return str
    .split(" ") // Split the string into an array of words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter and lowercase the rest
    .join(" "); // Join the words back into a single string
}

export function formatCurrency(amount) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD", // You can replace this with any currency code you need
    minimumFractionDigits: 2, // Ensures two decimal places (like 1,000.00)
    maximumFractionDigits: 2, // Avoids more than two decimal places
  }).format(amount);
}
