import { useSearchParams } from 'react-router-dom';

const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateQueryParams = (params = {}, options = { merge: true }) => {
    const updatedParams = new URLSearchParams(searchParams); 

    Object.entries(params).forEach(([key, value]) => {
      if (!value || value === 'any'|| value === 'Any') {
        updatedParams.delete(key);
      } else {
        updatedParams.set(key, value);
      }
    });

    if (options.merge) {
      setSearchParams(updatedParams); 
    } else {
      setSearchParams(new URLSearchParams(params));
    }
  };

  const deleteQueryParams = (keys = []) => {
    const updatedParams = new URLSearchParams(searchParams);

    if (keys.length === 0) {
      setSearchParams({});
    } else {
      keys.forEach((key) => updatedParams.delete(key));
      setSearchParams(updatedParams);
    }
  };

  return { searchParams, updateQueryParams, deleteQueryParams };
};

export default useQueryParams;
