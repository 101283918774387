import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { validateEmail } from "../../utils/validators";
import toast, { Toaster } from "react-hot-toast";
import "./AccessAccount.css";
import { Link } from "react-router-dom";

function AccessAccount() {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { sendMagicLink } = useAuth();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const refCode = params.get("ref");

    if (refCode) {
      window.localStorage.setItem("referralCode", refCode);
      toast.success("Referral code applied successfully!", {
        duration: 3000,
        position: "top-center",
      });
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (!validateEmail(email)) {
      setError(
        "Business emails only. Please use a professional email linked to your business domain"
      );
      return;
    }

    const loadingToast = toast.loading("Sending magic link...");
    setIsLoading(true);

    try {
      await sendMagicLink(email);
      toast.dismiss(loadingToast);
      toast.success("Magic link sent! Check your email to log in.", {
        duration: 5000,
        icon: "✉️",
      });
      setEmail("");
    } catch (error) {
      console.error("Error sending magic link:", error.message);
      toast.dismiss(loadingToast);
      setError("Failed to send magic link. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="access-page">
      <Toaster />

      <div className="container">
        <div className="access-card">
          <h2>Access Your Account</h2>
          <form onSubmit={handleSubmit}>
            <div className="error-message">{error}</div>
            <div className="input-group">
              <label htmlFor="email">Email Address</label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your work email"
                className={error ? "input-error" : ""}
              />
            </div>
            <button
              type="submit"
              disabled={isLoading}
              className={`btn ${isLoading ? "loading" : ""}`}
            >
              {isLoading ? "Sending Magic Link..." : "Send Magic Link"}
            </button>
          </form>
          <div className="form-footer">
            By continuing, you agree to Painworth Pro's
            <Link to="https://www.pro.painworth.com/terms-of-service" target="_blank">
             {" "} Terms of Service {" "}
            </Link>
            and
            <Link to="https://www.pro.painworth.com/privacy-policy" target="_blank">
            {" "} Privacy Policy {" "}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccessAccount;
