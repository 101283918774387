// src/components/Auth/EmailLinkHandler.js
import { useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { isSignInWithEmailLink } from "firebase/auth";
import { auth } from "../../firebase/firebase"; // Import the auth object

function EmailLinkHandler() {
  const { completeMagicLinkLogin } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const authLink = window.location.href;
    if (isSignInWithEmailLink(auth, authLink)) {
      completeMagicLinkLogin(authLink)
        .then(() => {
          // navigate("/dashboard");
        })
        .catch((error) => {
          console.error("Error completing sign-in:", error);
        });
    }
  }, [completeMagicLinkLogin, navigate]);

  return null; // Or a loading indicator
}

export default EmailLinkHandler;
