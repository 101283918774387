import React from "react";
import toast, { Toaster } from "react-hot-toast";
import { FaCopy, FaTwitter } from "react-icons/fa";
import { MdMail } from "react-icons/md";
import { SiFacebook } from "react-icons/si";
import { useUserData } from "../../contexts/UserDataContext";
import "./Dashboard.css";

function Dashboard() {
  const {
    credits,
    referrals,
    referralLink,
    unlimitedAccess,
    unlimitedAccessExpiry,
  } = useUserData();

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(referralLink)
      .then(() => toast.success("Referral link copied to clipboard!"))
      .catch(() => toast.error("Failed to copy link"));
  };

  return (
    <div className="container dashboard-container ">
      <Toaster />
      <h1>Dashboard</h1>
      <h4>Earn 2 search credits for every colleague you sign up. </h4>

      <p className="p1">
        Remaining Credits:{" "}
        <span
          style={{
            color: credits === 0 ? "red" : "",
          }}
        >
          {credits}
        </span>
      </p>
      {/* show messahe if credit are 0 */}

      <p
        style={{
          color: credits === 0 ? "red" : "black",
        }}
      >
        {credits === 0
          ? "You have run out of credits. Refer 5 friends to get unlimited access!"
          : ""}
      </p>

      <p className="p1">
        Referrals: {referrals} {referrals > 5 ? "" : "/ 5"}
      </p>
      <div className="progress-bar-container">
        <span>{referrals > 5 ? "Completed" : `${referrals} / 5`}</span>
        <div
          className="progress-bar-fill"
          style={{
            width: `${(referrals / 5) * 100}%`,
          }}
        ></div>
      </div>

      <p style={{ fontWeight: "300", color: "black" }}>
        Unlimited Access:{" "}
        <span
          style={{
            color: unlimitedAccess ? "green" : "red",
          }}
        >
          {unlimitedAccess
            ? `Active until ${unlimitedAccessExpiry?.toLocaleDateString()}`
            : "Inactive"}
        </span>
      </p>

      <div className="referral-section">
        <input
          type="text"
          value={referralLink}
          readOnly
          className="referral-link-input"
        />
        <button onClick={handleCopyLink} className="copy-btn">
          Copy Link <FaCopy />
        </button>
      </div>
      <div className="invite-friends-section">
        <p>Invite Friends:</p>
        <button
          className="btn bg-gray ml-2"
          onClick={() =>
            window.open(
              `mailto:?subject=Join LawSearchApp&body=Use my referral link: ${referralLink}`
            )
          }
        >
          <span className="btn-social">
            Email <MdMail />
          </span>
        </button>
        <button
          className="btn bg-gray ml-2"
          onClick={() =>
            window.open(
              `https://www.facebook.com/sharer/sharer.php?u=${referralLink}`
            )
          }
        >
          <span className="btn-social">
            Facebook <SiFacebook />
          </span>
        </button>
        <button
          className="btn bg-gray ml-2"
          onClick={() =>
            window.open(`https://twitter.com/intent/tweet?url=${referralLink}`)
          }
        >
          <span className="btn-social">
            Twitter <FaTwitter />
          </span>
        </button>
        <p>Earn unlimited search credits for 3 months If you sign up 5 users.</p>
      </div>
    </div>
  );
}

export default Dashboard;
