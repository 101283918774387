import ReactPaginate from 'react-paginate';
import "./Pagination.css"; // optional for additional custom styling
const Pagination = ({ pageCount, currentPage, onPageChange }) => {
    return (
        <ReactPaginate
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}


            onPageChange={onPageChange}
            containerClassName="pagination"
            pageClassName="pagination-page"
            pageLinkClassName="pagination-link"
            activeClassName="pagination-active"
            previousClassName="pagination-prev"
            nextClassName="pagination-next"
            breakClassName="pagination-break"
            disabledClassName="pagination-disabled"
            activeLinkClassName='activex'

            forcePage={currentPage - 1} // force selected page
        />
    );
};

export default Pagination;
