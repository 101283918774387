// src/utils/validators.js
const freeEmailDomains = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'outlook.com',
  'aol.com',
  'icloud.com',
  'mail.com',
  'zoho.com',
  'protonmail.com',
  // Add more free email domains as needed
];

const disposableEmailDomains = [
  'mailinator.com',
  '10minutemail.com',
  'temp-mail.org',
  'guerrillamail.com',
  'getnada.com',
  'dropmail.me',
  // Add more disposable email domains as needed
];

export function validateEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) return false;

  if (email.includes('+')) {
    // Reject alias emails
    return false;
  }

  const domain = email.split('@')[1].toLowerCase();

  if (freeEmailDomains.includes(domain) || disposableEmailDomains.includes(domain)) {
    return false;
  }

  return true;
}
