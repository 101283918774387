import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { states } from "../../constants";
import { useAuth } from "../../contexts/AuthContext";
import { useUserData } from "../../contexts/UserDataContext";
import useQueryParams from "../../hooks/useQueryParams";
import "./SearchForm.css";
import useIsMobile from "../../hooks/isMobile";

function SearchForm() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { credits, unlimitedAccess, loading } = useUserData();
  const { searchParams } = useQueryParams();
  const [state, setState] = useState("");
  const [keywords, setKeywords] = useState("");
  const [error, setError] = useState("");

  const isMobile = useIsMobile();

  const hasAvailableCredits = currentUser?.uid
    ? credits > 0 || unlimitedAccess
    : true;

  const customSelectStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      padding: "0",
      minHeight: "38px",
      border: `1px solid ${state.isFocused ? "#4ca9e7" : "#cccccc"}`,
      borderRadius: "6px",
      fontSize: "16px",
      width: !isMobile ? "200px" : "90vw",
      opacity: "1",
      cursor: "pointer",
    }),
    input: (baseStyles) => ({
      ...baseStyles,
      padding: "0",
      margin: "0",
    }),
    valueContainer: (baseStyles) => ({
      ...baseStyles,
      padding: "12px",
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      fontSize: "16px",
    }),
    // option: (baseStyles, { isFocused }) => ({
    //   ...baseStyles,
    //   padding: "12px",
    //   backgroundColor: isFocused ? "#f0f0f0" : "white",
    // }),
    option: (baseStyles, { isSelected, isFocused }) => ({
      ...baseStyles,
      padding: "12px",
      backgroundColor: isSelected ? "#cccc" : isFocused ? "#f0f0f0" : "white",
      color: isSelected ? "black" : "inherit",
      "&:active": {
        backgroundColor: isSelected ? "#000" : "#000",
      },
    }),
  };

  useEffect(() => {
    const stateParam = searchParams.get("state");
    const keywordsParam = searchParams.get("keywords");
    if (stateParam) {
      const foundState = states.find((s) => s.value === stateParam);
      setState(foundState || "");
    }
    if (keywordsParam) {
      setKeywords(keywordsParam);
    }
  }, [location.search]);

  const handleSearch = (e) => {
    e.preventDefault();

    // if (!hasAvailableCredits) {
    //   toast.error(
    //     "You have no credits available. Invite a friend to earn 2 credits."
    //   );
    //   return;
    // }

    if (!state || !keywords) {
      setError("Please enter both State and Keywords.");
      return;
    }

    navigate(`/search?state=${state.value}&keywords=${keywords}&page=1`);
  };

  return (
    <div className="container">
      <form onSubmit={handleSearch} className="search-form">
        <h2 className="form-title">Search Cases</h2>

        {!hasAvailableCredits && !loading && credits !== null ? (
          <div className="alert alert-error">
            You have no credits available. Invite a friend to earn 2 credits.
          </div>
        ) : null}

        <div className="form-row">
          <Select
            value={state}
            onChange={(selectedOption) => setState(selectedOption)}
            options={states}
            placeholder="Select State"
            isClearable
            required
            // isDisabled={!hasAvailableCredits}
            styles={customSelectStyles}
          />
          <input
            type="text"
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
            placeholder="Keywords (e.g. Skull Fracture, Surgery, Depression)"
            required
            className={`text-input `}
            // disabled={!hasAvailableCredits}
          />

          <span className="search-note">
            Each search requires 1 search credit
          </span>
        </div>
        <button
          type="submit"
          // className={`btn ${!hasAvailableCredits ? "disabled" : ""}`}
          className="search-button "
          disabled={loading}
        >
          Search
        </button>
      </form>
      {error && <p className="error-text">{error}</p>}
    </div>
  );
}

export default SearchForm;
