import { createContext, useContext, useEffect, useState } from "react";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { useAuth } from "./AuthContext";
import toast from "react-hot-toast";

const UserDataContext = createContext();

export function UserDataProvider({ children }) {
  const { currentUser, sendNotificationEmail } = useAuth();
  const [loading, setLoading] = useState(true);
  const [credits, setCredits] = useState(null);
  const [referrals, setReferrals] = useState(0);
  const [referralLink, setReferralLink] = useState("");
  const [unlimitedAccess, setUnlimitedAccess] = useState(null);
  const [unlimitedAccessExpiry, setUnlimitedAccessExpiry] = useState(null);
 
  useEffect(() => {
    if (!currentUser) {  
      setLoading(false);
      setUnlimitedAccess(false)
      setCredits(0)
      return;
    };

    const firestore = getFirestore();
    const userDocRef = doc(firestore, "users", currentUser.uid);

    // Set up realtime listener
    const unsubscribe = onSnapshot(
      userDocRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          setCredits(data.credits);
          setReferrals(data.referralsCount || 0);
          setReferralLink(
            `${window.location.origin}/access-account?ref=${data.referralCode}`
          );

          // Handle unlimited access status
          const unlimitedUntil = data.unlimitedUntil
            ? new Date(data.unlimitedUntil.toDate())
            : null;
          const now = new Date();
          setUnlimitedAccess(unlimitedUntil ? unlimitedUntil > now : false);
          setUnlimitedAccessExpiry(unlimitedUntil);

          // Check for expiration notification
          if (unlimitedUntil) {
            const timeLeft = unlimitedUntil - now;
            const daysLeft = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
            if (daysLeft <= 10 && daysLeft > 0) {
              sendNotificationEmail(
                currentUser.email,
                `Your unlimited access period expires in ${daysLeft} days.`
              );
            }
          }
        }
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching user data:", error);
        toast.error("Failed to fetch user data");
      }
    );

    return () => unsubscribe();
  }, [currentUser, sendNotificationEmail]);

  const value = {
    credits,
    referrals,
    referralLink,
    unlimitedAccess,
    unlimitedAccessExpiry,
    loading,
  };

  return (
    <UserDataContext.Provider value={value}>
      {children}
    </UserDataContext.Provider>
  );
}

export function useUserData() {
  const context = useContext(UserDataContext);
  if (context === undefined) {
    throw new Error("useUserData must be used within a UserDataProvider");
  }
  return context;
}
