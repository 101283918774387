// src/contexts/AuthContext.js
import React, { useContext, useState, useEffect } from "react";
import {
  signInWithEmailLink,
  sendSignInLinkToEmail,
  setPersistence,
  browserLocalPersistence,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
//import toaster form react-hot-toast
import { toast } from "react-hot-toast";

import { auth, firestore } from "../firebase/firebase";
import { validateEmail } from "../utils/validators";
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  runTransaction,
  increment,
  serverTimestamp,
} from "firebase/firestore";
import useQueryParams from "../hooks/useQueryParams";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { deleteQueryParams } = useQueryParams();
  useEffect(() => {
    // Set Firebase persistence to LOCAL to ensure the session persists.
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        // console.log("Persistence set to LOCAL");
      })
      .catch((error) => {
        console.error("Error setting persistence:", error);
      });

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
      // console.log("Auth state changed - currentUser:", user);
    });
    return unsubscribe;
  }, []);

  const generateUniqueCode = () => {
    return Math.random().toString(36).slice(2, 11); // Same as substr(2, 9)
  };

  // const decrementCredit = async () => {
  //   // get the user document by currentUser id
  //   if (!currentUser?.uid) return;
  //   const userDocRef = doc(firestore, "users", currentUser.uid);
  //   const userDoc = await getDoc(userDocRef);
  //   if (userDoc.exists()) {
  //     const user = userDoc.data();

  //     const credits = user.credits;
  //     if (
  //       credits > 0 &&
  //       (!user.unlimitedUntil || user.unlimitedUntil?.toDate() < new Date())
  //     ) {
  //       await updateDoc(userDocRef, {
  //         credits: increment(-1),
  //       });
  //       //if after this decrement its 0 then show a message that they have 0 credits left do a toast
  //       if (credits === 1) {
  //         toast.error("You have 0 credits left");
  //         //send an email with a nice message to reffer frined to gain some credits
  //         sendNotificationEmail(
  //           user.email,
  //           "You have 0 credits left, refer a friend to gain more credits"
  //         );
  //       }

  //       console.log("Credit decremented successfully.");
  //     } else {
  //       if (user.unlimitedUntil?.toDate() > new Date()) {
  //         console.log("User has unlimited access.");
  //       } else {
  //         console.log("No credits left.");
  //       }
  //     }
  //   }
  // };
  const decrementCredit = async () => {
    if (!currentUser?.uid) return;
    const userDocRef = doc(firestore, "users", currentUser.uid);

    try {
      await runTransaction(firestore, async (transaction) => {
        const userDoc = await transaction.get(userDocRef);
        if (!userDoc.exists()) {
          throw new Error("User document not found");
        }

        const userData = userDoc.data();
        const currentCredits = userData.credits;
        const unlimitedUntil = userData.unlimitedUntil?.toDate();
        const now = new Date();

        // Check if user has unlimited access
        if (unlimitedUntil && unlimitedUntil > now) {
          return; // Exit without decrementing
        }

        // Check if user has credits
        if (currentCredits <= 0) {
          throw new Error("No credits available");
        }

        // Perform the update atomically
        transaction.update(userDocRef, {
          credits: increment(-1),
          lastCreditDeduction: serverTimestamp(),
        });
        if (currentCredits === 1) {
          toast.error("You have 0 credits left");
          //send an email with a nice message to reffer frined to gain some credits
          sendNotificationEmail(
            userData.email,
            "You have 0 credits left, refer a friend to gain more credits"
          );
        }
      });

      console.log("Credit decremented successfully");
    } catch (error) {
      console.error("Error decrementing credit:", error);
      
    }
  };

  const sendNotificationEmail = async (toEmail, message) => {
    try {
      console.log(
        "Sending notification email to:",
        toEmail,
        "Message:",
        message
      );

      // Get the current Firebase auth token if user is logged in
      const idToken = currentUser ? await currentUser.getIdToken() : null;

      const functionUrl =
        process.env.REACT_APP_FUNC_API_URL ||
        "https://us-central1-usa-verdicts-and-settlements.cloudfunctions.net";

      const response = await fetch(functionUrl + "/sendNotificationEmail", {
        method: "POST",
        mode: "cors", // Enable CORS
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          ...(idToken && { Authorization: `Bearer ${idToken}` }),
        },
        body: JSON.stringify({
          email: toEmail,
          message: message,
        }),
      });

      if (!response.ok) {
        const errorData = await response.text();
        throw new Error(`Failed to send notification: ${errorData}`);
      }

      console.log("Notification email sent successfully");
      return true;
    } catch (error) {
      console.error("Error sending notification email:", error);
      throw error;
    }
  };

  const sendMagicLink = async (email) => {
    try {
      if (!validateEmail(email)) {
        throw new Error("Invalid work email address");
      }

      const actionCodeSettings = {
        url: window.location.origin,
        handleCodeInApp: true,
      };

      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      console.log("Magic link sent to:", email);

      // Save the email locally to complete sign-in later
      window.localStorage.setItem("emailForSignIn", email);
    } catch (error) {
      console.error("Error sending magic link:", error);
      throw error;
    }
  };

  const completeMagicLinkLogin = async (link) => {
    try {
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        email = window.prompt("Please provide your email for confirmation");
        if (!email) {
          throw new Error("Email is required to complete sign-in.");
        }
      }

      await signInWithEmailLink(auth, email, link);
      console.log("User logged in:", email);
      window.localStorage.removeItem("emailForSignIn");

      // Wait for auth state to be settled
      return new Promise((resolve, reject) => {
        onAuthStateChanged(auth, async (user) => {
          if (user) {
            setCurrentUser(user);
            const userDocRef = doc(firestore, "users", user?.uid);
            const userDoc = await getDoc(userDocRef);

            if (!userDoc.exists()) {
              // New user, create user document
              const referralCode = generateUniqueCode();
              const storedRefCode = window.localStorage.getItem("referralCode");

              const userData = {
                email: email,
                referralCode: referralCode,
                credits: 2, // New user gets 2 credits
                referredBy: storedRefCode || null,
                referralsCount: 0,
                unlimitedUntil: null,
                lastReferralRewardDate: null,
              };

              await setDoc(userDocRef, userData);
              console.log("User document created:", userData);

              // If the user was referred, update referrer's credits
              if (storedRefCode) {
                await handleReferralCredit(storedRefCode);
              }
            } else {
              checkIfUnlimitedAccessExpired(userDoc.data(), user?.uid);
            }
            deleteQueryParams(["apiKey", "oobCode"]);
            resolve();
          } else {
            reject(new Error("User authentication failed."));
          }
        });
      });
    } catch (error) {
      console.error("Error completing sign-in:", error);
      if (error.code === "auth/invalid-action-code") {
        console.error(
          "Invalid action code: The email link is either malformed, expired, or used."
        );
      }
    }
  };

  const handleReferralCredit = async (referralCode) => {
    try {
      const usersRef = collection(firestore, "users");
      const q = query(usersRef, where("referralCode", "==", referralCode));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const referrerDoc = querySnapshot.docs[0];
        const referrerData = referrerDoc.data();
        console.log("Refferer data:", referrerData);

        const referrerDocRef = referrerDoc.ref;

        // Increment credits and referralsCount atomically
        await updateDoc(referrerDocRef, {
          credits: increment(2),
          referralsCount: increment(1),
        });

        console.log("Referrer credits and referrals count updated.");

        // Fetch the updated referrer document to check the referral count
        const updatedReferrerDoc = await getDoc(referrerDocRef);
        const updatedReferrerData = updatedReferrerDoc.data();

        // Check if referrer has reached multiples of 5 referrals
        if (updatedReferrerData.referralsCount % 5 === 0) {
          // Unlock unlimited searches for 3 months
          const unlimitedUntil = new Date();
          unlimitedUntil.setMonth(unlimitedUntil.getMonth() + 3);
          await updateDoc(referrerDocRef, {
            unlimitedUntil,
            referralsCount: 0,
          });

          console.log("Unlimited searches unlocked for 3 months.");

          // Send notification email using SendGrid or any other service
          await sendNotificationEmail(
            referrerData.email,
            "You have unlocked unlimited searches for 3 months!"
          );
        } else {
          // Send notification email about earned credits
          await sendNotificationEmail(
            referrerData.email,
            "You have earned 2 search credits!"
          );
          console.log("Credits notification email sent to referrer.");
        }
        window.localStorage.removeItem("referralCode");
      } else {
        console.error("Referral code not found.");
      }
    } catch (error) {
      console.error("Error handling referral credit:", error);
    }
  };

  const checkIfUnlimitedAccessExpired = async (userProvided = null, userId) => {
    try {
      console.log("userProvied", userProvided);

      // Get user data either from provided object or Firestore
      let user = userProvided;
      let userDocRef;

      if (!user) {
        userDocRef = doc(firestore, "users", currentUser?.uid);
        const userDoc = await getDoc(userDocRef);
        if (!userDoc.exists()) {
          throw new Error("User document not found");
        }
        user = userDoc.data();
      }

      // Initialize document reference if not already set
      if (!userDocRef) {
        userDocRef = doc(firestore, "users", userId);
      }

      // Check referrals and grant unlimited access if applicable
      if (user?.referralsCount >= 5) {
        const unlimitedUntil = new Date();
        unlimitedUntil.setMonth(unlimitedUntil.getMonth() + 3);

        const updates = {
          unlimitedUntil,
          referralsCount: 0,
          lastReferralRewardDate: new Date(),
        };

        await Promise.all([
          updateDoc(userDocRef, updates),

          sendNotificationEmail(
            user.email,
            "You have unlocked unlimited searches for 3 months!",
            {
              expiryDate: unlimitedUntil.toLocaleDateString(),
              userName: user.name,
            }
          ),
        ]);

        return {
          status: "granted",
          expiryDate: unlimitedUntil,
          message: "Unlimited access granted for 3 months",
        };
      }

      // Check if unlimited access has expired
      if (user?.unlimitedUntil) {
        const currentDate = new Date();
        const expiryDate = user.unlimitedUntil.toDate();

        if (currentDate > expiryDate) {
          await updateDoc(userDocRef, {
            unlimitedUntil: null,
            accessExpiredAt: currentDate,
          });

          return {
            status: "expired",
            message: "Unlimited access has expired",
          };
        }

        return {
          status: "active",
          expiryDate,
          message: "Unlimited access is active",
        };
      }

      return {
        status: "inactive",
        message: "No unlimited access",
      };
    } catch (error) {
      console.error("Error checking unlimited access:", error);
      throw new Error(`Failed to check unlimited access: ${error.message}`);
    }
  };
  const logout = async () => {
    try {
      await signOut(auth);
      console.log("User logged out");
    } catch (error) {
      console.error("Logout error:", error);
      alert("Logout failed. Please try again.");
    }
  };

  const value = {
    currentUser,
    sendMagicLink,
    completeMagicLinkLogin,
    sendNotificationEmail,
    logout,
    loading,
    decrementCredit,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export { AuthContext };
