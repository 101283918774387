import React, { useState, useEffect } from 'react';
import { FaArrowCircleUp } from 'react-icons/fa';

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled up to given distance
  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set up scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  // Scroll to top smoothly
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <style>
        {`
          .scroll-to-top {
            position: fixed;
            bottom: 40px;
            right: 40px;
            background: none;
            border: none;
            padding: 0;
            cursor: pointer;
            transition: all 0.3s ease;
            opacity: 0;
            visibility: hidden;
            z-index: 1000;
          }

          .scroll-to-top.visible {
            opacity: 1;
            visibility: visible;
          }

          .scroll-to-top:hover {
            transform: translateY(-5px);
          }

          .scroll-to-top .icon {
            color: #898c94 ;
            font-size: 3rem;
            transition: all 0.3s ease;
          }

          .scroll-to-top:hover .icon {
            color: #0f0f65 ;
            filter: drop-shadow(0 4px 6px #898c94);
          }

          @media (max-width: 768px) {
            .scroll-to-top {
              bottom: 10px;
              right: 10px;
            }

            .scroll-to-top .icon {
              font-size: 2.3rem;
            }
          }

          @keyframes bounce {
            0%, 100% { transform: translateY(0); }
            50% { transform: translateY(-2px); }
          }

          .scroll-to-top.visible .icon {
            animation: bounce 2s infinite;
          }
        `}
      </style>
      <button
        onClick={scrollToTop}
        className={`scroll-to-top ${isVisible ? 'visible' : ''}`}
        aria-label="Scroll to top"
      >
        <FaArrowCircleUp className="icon" />
      </button>
    </>
  );
};

export default ScrollToTop;