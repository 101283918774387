import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { getCountyOrientation, sortOptions, states } from "../../constants";
import { useAuth } from "../../contexts/AuthContext";
import { useUserData } from "../../contexts/UserDataContext";
import useCasesSearch from "../../hooks/useCasesSearch";
import useQueryParams from "../../hooks/useQueryParams";
import { capitalizeWords, formatCurrency } from "../../utils/helpers"; // Moved formatCurrency here
import LoadingSpinner from "../common/Spinner";
import FilterBoxex from "./FilterBoxex";
import Pagination from "./Pagination";
import "./SearchResults.css";

const SearchResults = () => {
  const { searchParams, updateQueryParams } = useQueryParams();
  const { currentUser } = useAuth();
  const { credits, unlimitedAccess, loading } = useUserData();

  const sort = searchParams.get("sort");
  const {
    cases: data,
    totalResults,
    isLoading,
    hasValidSearch,
    error,
    analytics,
    ITEMS_PER_PAGE,
  } = useCasesSearch(
    searchParams,
    updateQueryParams,
    credits,
    unlimitedAccess,
    loading
  );

  const sortCases = (cases) => {
    if (sort && sort.toLowerCase() === sortOptions[0].value.toLowerCase()) {
      return cases.sort((a, b) => b.date.toDate() - a.date.toDate());
    } else if (
      sort &&
      sort.toLowerCase() === sortOptions[1].value.toLowerCase()
    ) {
      return cases.sort((a, b) => a.date.toDate() - b.date.toDate());
    } else if (
      sort &&
      sort.toLowerCase() === sortOptions[2].value.toLowerCase()
    ) {
      return cases.sort((a, b) => b.totalAwarded - a.totalAwarded);
    } else if (
      sort &&
      sort.toLowerCase() === sortOptions[3].value.toLowerCase()
    ) {
      return cases.sort((a, b) => a.totalAwarded - b.totalAwarded);
    } else {
      return cases;
    }
  };

  const currentPage = parseInt(searchParams.get("page")) || 1;
  const stateName = getStateName(searchParams.get("state"));
  const keywords = getKeywords(searchParams.get("keywords"));

  const handlePageChange = ({ selected }) => {
    updateQueryParams({ page: selected + 1 });
  };

  const cases = sortCases(data);

  return (
    <div>
      <SearchHeader stateName={stateName} keywords={keywords} />
      <div className="outerSearchResult">
        <div className="innerSearchResult">
          {error && <p style={{ color: "red" }}>{error}</p>}
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <div className="container">
              <SearchSummary
                totalResults={totalResults}
                analytics={analytics}
              />
              {/* {JSON.stringify({ credits, unlimitedAccess, hasValidSearch })} */}
              <div className="seperator"></div>
              {currentUser ? (
                <AuthenticatedCaseResults
                  cases={cases}
                  credits={credits}
                  unlimitedAccess={unlimitedAccess}
                  hasValidSearch={hasValidSearch}
                  isLoading={isLoading}
                />
              ) : (
                // <>
                // </>
                <UnauthenticatedMessage />
              )}
              {totalResults && currentUser && hasValidSearch ? (
                <PaginationWrapper
                  totalResults={totalResults}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                  itemsPerPage={ITEMS_PER_PAGE}
                />
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const AuthenticatedCaseResults = ({
  cases,
  unlimitedAccess,
  credits,
  hasValidSearch,
}) => {
  const { searchParams } = useQueryParams();
  const state = searchParams.get("state");
  const { decrementCredit } = useAuth();

  // Show no credits message only when attempting a new search with 0 credits
  useEffect(() => {
    if (hasValidSearch && credits > 0 && !unlimitedAccess) {
      decrementCredit();
    }
  }, []);
  if (!hasValidSearch) {
    return (
      <div className="">
        <p className="alert-msg">
          You don't have enough credits for detailed results. Invite more
          colleagues to earn credits.
        </p>
      </div>
    );
  }

  if (cases.length === 0)
    return <p className="alert-msg">No cases match your search criteria.</p>;
  return (
    <div className="container">
      <CasesHeader />
      <div className="case-list">
        {cases.map((caseItem) => (
          <CaseCard key={caseItem.id} caseItem={caseItem} state={state} />
        ))}
      </div>
    </div>
  );
};
const SearchHeader = ({ stateName, keywords }) => (
  <div className="upperResults">
    <div className="innerResult">
      <div className="outerTitle">
        <div className="title">
          <h1>{stateName}</h1>
          <div>
            {keywords.map((keyword, index) => (
              <p key={index}>{keyword.toUpperCase()}</p>
            ))}
          </div>
        </div>
      </div>
      <div className="filters">
        <p>Filters</p>
        <FilterBoxex />
      </div>
    </div>
  </div>
);
const colorsOfOrientation = {
  Plaintiff: "#ffc0c3",
  Neutral: "#ffff99",
  Defense: "#c1c1ff",
  "N/A": "#ffff99",
  "n/a": "#ffff99",
};
const SearchSummary = ({ totalResults, analytics }) => {
  // get first three entries of the colorsOfOrientation object
  const orientationColors = Object.entries(colorsOfOrientation).slice(0, 3);

  return (
    <div className="innerSearch">
      <h2 className="lead-1">{totalResults} Matching Cases</h2>

      {totalResults > 0 ? <h2 className="lead-1">Statistics</h2> : null}

      {analytics && totalResults && (
        <div className="local">
          <div className="range">
            <p>
              Award Range:    
              <span>
                {analytics.range.min} - {analytics.range.max}
              </span>
            </p>
            <p>
              Median Value:    <span>{analytics.medianValue}</span>
            </p>
          </div>
          <div className="highest">
            <p>
              Lowest County:   
              <span style={{ textTransform: "capitalize" }}>
                {analytics.lowestCounty}
              </span>
            </p>
            <p>
              Highest County:  
              <span style={{ textTransform: "capitalize" }}>
                {analytics.highestCounty}
              </span>
            </p>
            <div className="orientation-colors">
              {orientationColors.map(([orientation, color]) => {
                return (
                  <div key={orientation} className="orientation">
                    <span
                      style={{
                        backgroundColor: color,
                        padding: "2px 6px",
                        borderRadius: "4px",
                      }}
                    >
                      {orientation} Oriented County
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const PaginationWrapper = ({
  totalResults,
  currentPage,
  onPageChange,
  itemsPerPage,
}) => (
  <div style={{ marginTop: "120px" }}>
    <Pagination
      pageCount={Math.ceil(totalResults / itemsPerPage)}
      currentPage={currentPage}
      onPageChange={onPageChange}
    />
  </div>
);

const UnauthenticatedMessage = () => (
  <div className="loggedIn">
    <p>You must Log In to view cases</p>
    <p className="p2">
      <Link to="/access-account">
        <span className="span">Login</span>
      </Link>{" "}
    </p>
  </div>
);

const CasesHeader = () => {
  const { updateQueryParams } = useQueryParams();
  const handleSortChange = (e) => {
    const value = e.target.value;
    updateQueryParams({ sort: value });
  };
  return (
    <div className="cases-header">
      <h2 className="cases-title">Cases</h2>
      <select className="sort-select" onChange={handleSortChange}>
        <option value={""}>Sort By</option>
        {sortOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

const CaseCard = ({ caseItem, state }) => {
  const orientation = getCountyOrientation(
    state,
    capitalizeWords(caseItem.county.replace(/\s*(county)\b/i, "").trim())
  )?.trim();
  console.log(
    "caseItem.caseType",
    caseItem.caseType,
    "typeof caseItem.caseType",
    typeof caseItem.caseType
  );

  return (
    <div className="case-card">
      <div className="case-header">
        <h4 className="case-title">
          <Link to={caseItem?.sourceKey || "#"} target="_blank">
            {caseItem.caseName}, {caseItem.year}
          </Link>
        </h4>
        <span className="case-resolution">{caseItem.resolution}</span>
      </div>
      <div className="case-content">
        <div className="case-metadata">
          <span
            className="case-county"
            style={{
              backgroundColor: colorsOfOrientation[orientation] || "#ffff99",
              padding: "2px 6px",
              borderRadius: "4px",
            }}
          >
            {caseItem.county.replace(/\s*(county)\b/i, "").trim()}
          </span>
          <span>
            {Array.from(caseItem.caseType).map(
              (caseType, i) =>
                caseType + (i === caseItem.caseType.length - 1 ? "" : ", ")
            )}
          </span>
        </div>
        <p className="case-description">{caseItem.injuries}</p>
        <p className="case-description">{caseItem.treatments}</p>
        <p className="case-award">
          Total Award: {formatCurrency(caseItem.totalAwarded)}
        </p>
      </div>
    </div>
  );
};

// Utility functions
const getStateName = (state) => {
  const foundState = states.find((s) => s.value === state);
  return foundState ? foundState.label : "Unknown State";
};

const getKeywords = (keywords) => {
  return keywords ? keywords.split(",").map((keyword) => keyword.trim()) : [];
};

const checkUnlimitedAccess = (userData) => {
  const currentDate = new Date();
  return (
    userData.unlimitedUntil && currentDate <= userData.unlimitedUntil.toDate()
  );
};

export default SearchResults;
