const firebaseConfig = {
  // apiKey: "AIzaSyC-ZoM5HC4skdscIYtgAWbs2MTgEZ4MjaM",
  // authDomain: "usa-verdicts-and-settlements.firebaseapp.com",
  // projectId: "ali-haider-bf405",
  // storageBucket: "usa-verdicts-and-settlements.appspot.com",
  // messagingSenderId: "911381055155",
  // appId: "1:911381055155:android:ed73895d56d12ec94692c6",

  // REAL
  apiKey: "AIzaSyDVO8OYvLr3HDd3VQlz65ZiTn26kMeJBEk",

  authDomain: "painworth.law",

  projectId: "usa-verdicts-and-settlements",

  storageBucket: "usa-verdicts-and-settlements.appspot.com",

  messagingSenderId: "195195321502",

  appId: "1:195195321502:web:e05efe4052fba46940cf8f",
};

export default firebaseConfig;
