import { useState, useEffect } from 'react';

// Default breakpoint for mobile screens (768px is a common breakpoint)
const MOBILE_BREAKPOINT = 768;

const useIsMobile = (breakpoint = MOBILE_BREAKPOINT) => {
  // Initialize with current window width to avoid hydration mismatch
  const [isMobile, setIsMobile] = useState(() => {
    // Check if window is available (client-side)
    if (typeof window !== 'undefined') {
      return window.innerWidth < breakpoint;
    }
    // Default to false on server-side
    return false;
  });

  useEffect(() => {
    // Skip if window is not available (server-side)
    if (typeof window === 'undefined') return;

    // Handler to call on window resize
    const handleResize = () => {
      setIsMobile(window.innerWidth < breakpoint);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);
    
    // Call handler right away to update state with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [breakpoint]); // Re-run effect if breakpoint changes

  return isMobile;
};

export default useIsMobile;