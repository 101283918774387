// src/components/Home/Home.js
import React from "react";
import SearchForm from "../Search/SearchForm";
import Navbar from "../common/Navbar";

function Home() {
  return (
    <div className="home-container">
      {/* <Navbar /> */}
      <SearchForm />
    </div>
  );
}

export default Home;
